<template>
  <div class="page-root tools-cxResult-root">
    <div class="cxResult-page">
      <div class="title">在线评估 > 车型库查询 > <span>车辆详细配置信息</span></div>
      <div class="result-item2">
        <div class="title">车辆详细配置信息</div>
        <div class="list-bar">
          <div class="title"><span></span> 车辆信息</div>
          <div class="list">
            <div>
              所属品牌 ：<span>{{ tableData.brandName }}</span>
            </div>
            <div>
              车系 ：<span>{{ tableData.vehicleSeriesName }}</span>
            </div>
            <div>
              车型 ： <span>{{ tableData.vehicleTypeName }}</span>
            </div>
            <div>
              厂家指导价 ：<span>{{ tableData.price }}</span>
            </div>
            <div v-for="(val, key, i) in tableData['基本参数']" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 发动机&变速箱</div>
          <div class="list">
            <div v-for="(val, key, i) in Object.assign(tableData['发动机'] || {}, tableData['变速箱'] || {})" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 底盘转向＆车轮制动</div>
          <div class="list">
            <div
              v-for="(val, key, i) in Object.assign(tableData['底盘转向'] || {}, tableData['车轮制动'] || {})"
              :key="i"
            >
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 安全装备</div>
          <div class="list">
            <div v-for="(val, key, i) in Object.assign(tableData['主/被动安全装备'] || {})" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 操控配置</div>
          <div class="list">
            <div v-for="(val, key, i) in Object.assign(tableData['辅助/操控配置'] || {})" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 外部配置</div>
          <div class="list">
            <div v-for="(val, key, i) in Object.assign(tableData['外部/防盗配置'] || {})" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="list-bar">
          <div class="title"><span></span> 内部配置</div>
          <div class="list">
            <div v-for="(val, key, i) in Object.assign(tableData['内部配置'] || {})" :key="i">
              {{ key }} : <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="tips">* 以上数据仅供参考，最终车辆信息以实地车辆为准</div>
      </div>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
export default {
  name: 'Tools-cxResult',
  data() {
    return {
      tableData: this.$route.params,
    };
  },
  mounted() {
    document.getElementById('app').scrollTo(0, 0);
  },
};
</script>
